import React from "react";
import { Link } from "gatsby";

import marked from 'marked';

export default (props) => (
  <li>
    <h3 className="title-block">
      <Link to={`/notes/${props.post.fields.slug}`}>
        <span
          className="title"
          dangerouslySetInnerHTML={
            { __html:
              marked(props.post.frontmatter.title).replace('<p>', '').replace('</p>', '')
            }}
        />
        {' '}
        <span className="date">{props.post.frontmatter.date}</span>
      </Link>
    </h3>
    {(!props.suppressSummary) ? (
      <p>
        {props.post.frontmatter.summary}<br />
        <Link to={`/notes${props.post.fields.slug}`}>
          Read more…
        </Link>
      </p>
    ) : null}
  </li>
);